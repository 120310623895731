import { useAuth } from "@src/routes/authProvider";
import { paths } from "@src/routes/paths";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import styles from "./Home.module.scss";
import SupportPets_Dont_Leave_Them_Behind from "@src/img/SupportPets_Dont_Leave_Them_Behind.png";
import dashboard_footer_logo from "@src/img/dashboard_footer_logo.jpg";

import ESA_logo from "@src/img/ESA_logo.png";
import { clx } from "@src/components/utils/stringUtils";
export const Home: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated && location.pathname !== paths.login) {
    return <Navigate to={paths.login} replace />;
  }

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <nav className={clx("navbar navbar-expand-lg", styles.navbar)}>
          <div className={clx("container-fluid", styles.fluid)}>
            <a className={styles.brand} href="/">
              <img src={ESA_logo} className={styles.logo} alt="ESA" /> ESA
              Access
              <img
                src={SupportPets_Dont_Leave_Them_Behind}
                className={styles.flyer}
                alt="SupportPets - Dont Leave them behind"
              />
            </a>

            <button
              className={clx("navbar-toggler", styles.toggler)}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className={clx("bi bi-list", styles.icon)}></i>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2">
                <li className="nav-item dropdown">
                  <button
                    className={clx(
                      "nav-link dropdown-toggle",
                      styles.whiteText
                    )}
                    id="profileDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="bi bi-person"></i> Profile
                  </button>
                  <ul
                    className={clx(
                      "dropdown-menu dropdown-menu-end",
                      styles.dropdown
                    )}
                    aria-labelledby="profileDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="./reset">
                        Reset Password
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <button
                    className={clx("nav-link", styles.whiteText)}
                    onClick={() => {
                      logout();
                    }}
                  >
                    <i className="bi bi-box-arrow-right"></i> Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <div className={styles.childRoutesContainer}>
        <Outlet />
      </div>
      <footer className={clx("dashboard-footer", styles.footer)}>
        <p className="mb-3">In Accordance with Federal Laws</p>
        <img
          className="footer-logo mb-3"
          src={dashboard_footer_logo}
          alt="logo"
        />
        <p className="mb-3">&copy; 2025 ESA Verification</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a
              href="https://www.supportpets.com/terms-conditions/"
              target="_blank"
            >
              Terms
            </a>
          </li>
          <li className="list-inline-item">
            <a
              href="https://www.supportpets.com/privacy-policy/"
              target="_blank"
            >
              Privacy
            </a>
          </li>
          <li className="list-inline-item">
            <a href="#">Doctors</a>
          </li>
        </ul>
      </footer>
    </div>
  );
};
