import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = {
  sent: false,
};
export const { thunk: forgot, slice: forgotSlice } = getThunkAndSlice<
  {
    email: string;
  },
  typeof initialState
>({
  initialState,
  path: "/user/send-reset-link",
  method: "post",
});
