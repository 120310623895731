import React from "react";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  userActions,
  RootState,
  useAppDispatch,
  settingsActions,
} from "@src/store";
import { toast } from "sonner";
import styles from "./Forgot.module.scss";
import ESA_logo from "@src/img/ESA_logo.png";
import { useSelector } from "react-redux";
import { clx } from "@src/components/utils/stringUtils";

export const ForgotValidation = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email."),
});
export type ForgotValidationProps = yup.InferType<typeof ForgotValidation>;

export const Forgot: React.FC = () => {
  const dispatch = useAppDispatch();
  const forgot = useSelector((x: RootState) => x.forgot);

  dispatch(
    settingsActions.updateSettings({
      backgroundColor: "#0b7cbb",
    })
  );

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<{
    email: string;
  }>({
    mode: "onChange",
    resolver: yupResolver(ForgotValidation),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<ForgotValidationProps> = async () => {
    try {
      const values = getValues();
      dispatch(
        userActions.forgot({
          email: values.email,
        })
      );
    } catch (err: unknown) {
      console.error(err);
      toast.warning("Login failed");
    }
  };

  return (
    <form className={styles.main} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.center}>
        <section className={clx("d-flex flex-column gap-3", styles.form)}>
          <div className={styles.header}>
            <h1 className={styles.title}>ESA Verification</h1>
            <img
              id="esa-logo"
              className={styles.logo}
              src={ESA_logo}
              alt="ESA logo"
            />
            <h2 className={styles.subtitle}>
              Please enter the email address associated with your documents to
              reset your account password.
            </h2>
          </div>
          {forgot.error && (
            <div className="alert alert-warning" role="alert">
              {JSON.stringify(forgot.error)}
            </div>
          )}
          {errors.email && touchedFields.email && (
            <div className="alert alert-warning" role="alert">
              {errors.email.message}
            </div>
          )}
          {forgot.data.sent && (
            <div
              className={clx("alert alert-success", styles.linkSent)}
              role="alert">
              <div className={styles.title}>
                Your password restore link was submitted.
              </div>
              <div className={styles.subtitle}>
                Check your email for the link to reset your password.
              </div>
              <div className={styles.hint}>
                <div>
                  <i className="bi bi-lightbulb me-1"></i>Can’t find the email
                  in your Incoming folder?
                </div>
                <ul>
                  <li>
                    <strong>Refresh your inbox</strong> to ensure your emails
                    are up to date.
                  </li>
                  <li>
                    <strong>Check your Spam/Junk folder</strong>—it might have
                    been filtered accidentally.
                  </li>
                  <li>
                    <strong>Verify the sender’s email address</strong> for typos
                    or errors.
                  </li>
                </ul>
                <p className={styles.note}>
                  If the issue persists, feel free to reach out to our support
                  team for assistance.
                </p>
              </div>
            </div>
          )}
          <div>
            <label className="form-label" htmlFor="email">
              Email:
            </label>
            <input
              type="text"
              className={clx({
                "form-control": true,
                "is-invalid": !!errors.email,
              })}
              id="email"
              {...register("email")}
            />
          </div>

          <div>
            <button
              disabled={isSubmitting || Object.keys(errors).length > 0}
              type="submit"
              className="btn btn-primary btn-large">
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Sending recover email
                </>
              ) : (
                "Send recover email"
              )}
            </button>
          </div>

          <div className="text-center">
            <a className="link-primary" href="./login">
              Login
            </a>
          </div>
        </section>

        <div className={styles.footer}>
          © {new Date().getFullYear()} ESA Verification
        </div>
      </div>
    </form>
  );
};
