import { clx } from "@src/components/utils/stringUtils";
import React from "react";
import styles from "./Box.module.scss";
import { LinkBtn } from "@src/components/linkBtn/LinkBtn";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export const Box: React.FC<{
  bgImage: string;
  linkHref: string;
  linkText: string;
  info: string;
}> = ({ bgImage, linkHref, linkText, info }) => {
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <div
        className={clx(styles.content)}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <LinkBtn href={linkHref} variant="secondary" text={linkText} />
      </div>
      <div className={styles.text}>{info}</div>
    </div>
  );
};
