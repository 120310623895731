import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
import { useSelector } from "react-redux";
import {
  RootState,
  settingsActions,
  useAppDispatch,
  userActions,
} from "@src/store";
import { clx } from "@src/components/utils/stringUtils";
import { Vip } from "./vip/Vip";
import { EsaDocuments } from "./esa-documents/EsaDocuments";
import { Chat } from "./chat/Chat";
import { Televet } from "./televet/Televet";

export const Dashboard: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("esa");

  const user = useSelector((x: RootState) => x.user);
  const dispatch = useAppDispatch();

  dispatch(
    settingsActions.updateSettings({
      backgroundColor: "#FFFFFF",
    })
  );

  useEffect(() => {
    dispatch(userActions.user());
  }, []);

  return (
    <div>
      {!user.init ? (
        <div className={styles.loading}>
          <span
            className="spinner-border spinner-border-sm"
            style={{ opacity: 0.5 }}></span>
          Loading...
        </div>
      ) : (
        <>
          <div className={styles.tabs}>
            <button
              className={clx({
                [styles.tab]: true,
                [styles.selected]: selectedTab === "esa",
              })}
              onClick={() => setSelectedTab("esa")}>
              ESA Documents
            </button>
            <button
              className={clx({
                [styles.tab]: true,
                [styles.selected]: selectedTab === "vip",
                [styles.disabled]: !user.data.hasVIP,
              })}
              onClick={() => setSelectedTab("vip")}>
              V.I.P. Club
            </button>
            <button
              className={clx({
                [styles.tab]: true,
                [styles.selected]: selectedTab === "televet",
                [styles.disabled]: !user.data.hasTelevet,
              })}
              onClick={() => setSelectedTab("televet")}>
              Televet
            </button>
            <button
              className={clx({
                [styles.tab]: true,
                [styles.selected]: selectedTab === "chat",
                [styles.disabled]: !user.data.hasChat,
              })}
              onClick={() => setSelectedTab("chat")}>
              Message center
            </button>
          </div>
          {selectedTab == "esa" && <EsaDocuments />}
          {selectedTab == "vip" && <Vip />}
          {selectedTab == "televet" && <Televet />}
          {selectedTab == "chat" && <Chat />}
        </>
      )}
    </div>
  );
};
