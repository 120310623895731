// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddToBtn-module_main___iORSM{display:flex;background-color:#1f1f1f;border-radius:10px;transition:opacity .3s;align-items:center}.AddToBtn-module_main___iORSM:hover{opacity:.9}.AddToBtn-module_main___iORSM .AddToBtn-module_img___bscw5{padding:6px}.AddToBtn-module_main___iORSM .AddToBtn-module_img___bscw5 img{height:32px}.AddToBtn-module_main___iORSM .AddToBtn-module_content___l5Xx6{display:flex;flex-direction:column;color:#fff;padding-right:10px;text-align:left}.AddToBtn-module_main___iORSM .AddToBtn-module_content___l5Xx6 .AddToBtn-module_header___mZGOS{font-size:13px;color:#fff;line-height:13px;white-space:nowrap}.AddToBtn-module_main___iORSM .AddToBtn-module_content___l5Xx6 .AddToBtn-module_subheader___mX7or{font-size:15px;color:#fff;line-height:15px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/vip/AppVip/AddToBtn/AddToBtn.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,wBAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,oCACE,UAAA,CAEF,2DACE,WAAA,CACA,+DACE,WAAA,CAGJ,+DACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CACA,+FACE,cAAA,CACA,UAAA,CACA,gBAAA,CACA,kBAAA,CAEF,kGACE,cAAA,CACA,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".main{display:flex;background-color:#1f1f1f;border-radius:10px;transition:opacity .3s;align-items:center}.main:hover{opacity:.9}.main .img{padding:6px}.main .img img{height:32px}.main .content{display:flex;flex-direction:column;color:#fff;padding-right:10px;text-align:left}.main .content .header{font-size:13px;color:#fff;line-height:13px;white-space:nowrap}.main .content .subheader{font-size:15px;color:#fff;line-height:15px;white-space:nowrap}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `AddToBtn-module_main___iORSM`,
	"img": `AddToBtn-module_img___bscw5`,
	"content": `AddToBtn-module_content___l5Xx6`,
	"header": `AddToBtn-module_header___mZGOS`,
	"subheader": `AddToBtn-module_subheader___mX7or`
};
export default ___CSS_LOADER_EXPORT___;
