import React, { useEffect, useRef, useState } from "react";
import styles from "./Chat.module.scss";
import { clx } from "@src/components/utils/stringUtils";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

interface Message {
  id: number;
  sender: "bot" | "user";
  text: string;
  hasAttachments: boolean;
}
export const Chat: React.FC = () => {
  const user = useSelector((x: RootState) => x.user);
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [messages, setMessages] = useState<Array<Message>>([
    {
      id: 1,
      sender: "bot",
      text: `As part of the Patient Care Team, I would like to welcome you...${isMobile} ${JSON.stringify(user)}`,
      hasAttachments: true,
    },
    {
      id: 2,
      sender: "user",
      text: "Thank you! I'd like to schedule an appointment.",
      hasAttachments: false,
    },
  ]);
  const [newMessage, setNewMessage] = useState<string>("");

  // Ref type set to HTMLDivElement
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Use optional chaining (?.) so it doesn’t throw if ref is null
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const tabs = [
    {
      id: 0,
      label: "Clinical",
      icon: <i className="bi-chat-dots-fill"></i>,
    },
    {
      id: 1,
      label: "Billing",
      icon: <i className="bi-chat-quote-fill"></i>,
    },
    {
      id: 2,
      label: "Customer",
      icon: <i className="bi-chat-square-dots-fill"></i>,
    },
    {
      id: 3,
      label: "Shipping",
      icon: <i className="bi-chat-text-fill"></i>,
    },
  ];

  const handleSendMessage = () => {
    if (!newMessage.trim()) return;
    const nextMessage: Message = {
      id: messages.length + 1,
      sender: "user",
      text: newMessage.trim(),
      hasAttachments: false,
    };
    setMessages((prev) => [...prev, nextMessage]);
    setNewMessage("");
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => setSelectedTab(tab.id)}
            className={clx({
              [styles.tab]: true,
              [styles.selected]: selectedTab === tab.id,
            })}>
            {tab.icon}
            <span>{tab.label}</span>
          </div>
        ))}
      </div>

      <div className={styles.panel}>
        <div className={styles.header}>
          {tabs[selectedTab].icon} {tabs[selectedTab].label}
        </div>

        <div className={styles.body}>
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={
                msg.sender === "user" ? styles.userMessage : styles.botMessage
              }>
              <div>{msg.text}</div>
              {msg.hasAttachments && (
                <div className={styles.attachments}>
                  <div>
                    Doctor signed.pdf <i className="bi bi-paperclip"></i>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <div className={styles.footer}>
          <textarea
            rows={1}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
          />
          <button className={styles.attachFileBtn} onClick={handleSendMessage}>
            Attach file
            <i className="bi bi-paperclip"></i>
          </button>
          <button className={styles.sendBtn} onClick={handleSendMessage}>
            Send
            <i className="bi bi-cursor"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
