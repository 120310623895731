import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import Player from "@vimeo/player";
import styles from "./MinimalPlayer.module.scss"; // SCSS module
import { RootState, useAppDispatch, userActions } from "@src/store";
import { useSelector } from "react-redux";
import playIcon from "@src/img/play-icon.png";

interface MinimalPlayerProps {
  vimeoId: string;
  originalWidth: number;
  originalHeight: number;
  width?: number;
  initialWasViewed?: boolean;
  showPercent?: number;
}

export const MinimalPlayer: React.FC<MinimalPlayerProps> = ({
  vimeoId,
  originalWidth,
  originalHeight,
  width = originalWidth * 0.3,
  initialWasViewed = false,
  showPercent = 0.1,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const hiddenControlRef = useRef<HTMLButtonElement>(null);
  const playerRef = useRef<Player | null>(null);
  const isPlaying = useRef(false);
  const isSaving = useRef(false);
  const wasViewed = useRef(initialWasViewed);
  const ratio = originalHeight / originalWidth;
  const viewed = useSelector(
    (state: RootState) => state.user.data.video_viewed
  );

  useEffect(() => {
    wasViewed.current = viewed;
  }, [viewed]);

  const dispatch = useAppDispatch();

  const onVisibilityChange = useCallback(() => {
    if (!playerRef.current) return;
    setPlayerStatus(playerRef.current, !document.hidden);
  }, []);

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  const handleTimeUpdate = (data: Player.TimeEvent) => {
    if (!progressBarRef.current) return;

    progressBarRef.current.style.width = `${data.percent * 100}%`;

    if (data.percent >= showPercent) saveWasViewed();
  };

  useEffect(() => {
    if (!iframeRef.current) return;

    playerRef.current = new Player(iframeRef.current);

    playerRef.current.on("timeupdate", (x) => {
      handleTimeUpdate(x);
    });

    return () => {
      playerRef.current?.off("timeupdate", handleTimeUpdate);
      playerRef.current?.destroy();
    };
  }, []);

  const setPlayerStatus = async (player: Player, play: boolean) => {
    if (hiddenControlRef.current)
      hiddenControlRef.current.style.opacity = play ? "0" : "0.5";
    if (play) {
      await player.play();
      await player.setVolume(1);
    } else {
      await player.pause();
    }
  };

  const saveWasViewed = async () => {
    if (isSaving.current || wasViewed.current) return;
    isSaving.current = true;

    try {
      await dispatch(
        userActions.videoViewed({
          video_viewed: true,
        })
      );
      wasViewed.current = true;
      isSaving.current = false;
    } catch (error) {
      console.error("Error saving view status:", error);
    }
  };

  return (
    <div
      className={styles.playerContainer}
      style={{
        width: `${width}px`,
        gridTemplateRows: `${Math.floor(width * ratio)}px 5px`,
      }}
    >
      <iframe
        ref={iframeRef}
        title="Video Player"
        className={styles.playerIframe}
        frameBorder="0"
        style={{
          width: `${width}px`,
          height: `${Math.floor(width * ratio)}px`,
        }}
        src={`https://player.vimeo.com/video/${vimeoId}&autoplay=0&title=0&byline=0&portrait=0&controls=0`}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />

      <button
        ref={hiddenControlRef}
        className={styles.hiddenControl}
        onClick={() => {
          if (!playerRef.current) return;

          isPlaying.current = !isPlaying.current;
          setPlayerStatus(playerRef.current, isPlaying.current);
        }}
        style={{
          opacity: isPlaying.current ? 0 : 0.5,
          backgroundImage: `url(${playIcon})`,
        }} // SVG
      />

      <div className={styles.progressContainer}>
        <div ref={progressBarRef} className={styles.progressBar} />
      </div>
    </div>
  );
};
