import React from "react";
import styles from "./Televet.module.scss";
import { clx } from "@src/components/utils/stringUtils";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export const Televet: React.FC = () => {
  const user = useSelector((x: RootState) => x.user);
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <div className={styles.header}>
        Welcome to Televet {user.data.firstname}
      </div>
    </div>
  );
};
