import React, { useEffect, useState } from "react";
import ESA_logo from "@src/img/ESA_logo.png";
import styles from "./Login.module.scss";
import { clx } from "@src/components/utils/stringUtils";
import { userActions, RootState, useAppDispatch, settingsActions } from "@src/store";
import { useSelector } from "react-redux";
import { useAuth } from "@src/routes/authProvider";
import * as yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { paths } from "@src/routes/paths";
import { passwordComplexityRegex } from "@src/store/helpers";

export const LoginValidation = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email."),
  password: yup
    .string()
    .required("Password field cant be empty.")
    .matches(
      passwordComplexityRegex,
      "Password must be 8-32 characters long, include at least one number, and one special character (!@#$%^&*)."
    ),
});

export type LoginValidationProps = yup.InferType<typeof LoginValidation>;

export const Login: React.FC = () => {
  const { isAuthenticated, login: uLogin, rememberMe } = useAuth();
  const dispatch = useAppDispatch();
  const login = useSelector((x: RootState) => x.login);
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  dispatch(
    settingsActions.updateSettings({
      backgroundColor: "#0b7cbb",
    })
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate(paths.home);
    }
  }, [isAuthenticated]);

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<LoginValidationProps>({
    mode: "onChange",
    resolver: yupResolver(LoginValidation),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<LoginValidationProps> = async () => {
    try {
      const values = getValues();
      const fetchLoginResult = await dispatch(
        userActions.login({
          email: values.email,
          password: values.password,
        })
      );
      const fetchLoginData = unwrapResult(fetchLoginResult);

      uLogin(fetchLoginData.token, rememberMe, false);
      navigate(paths.home);
    } catch (error) {
      console.error(error);
    }
  };

  const onError = () => {
    if (errors.email) setShowEmailError(true);
    if (errors.password) setShowPasswordError(true);
  };

  return (
    <form
      className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}
      onSubmit={handleSubmit(onSubmit, onError)}>
      <div className={styles.center}>
        <section className={clx("d-flex flex-column gap-3", styles.form)}>
          <div className={styles.header}>
            <h1 className={styles.title}>ESA Verification</h1>
            <img
              id="esa-logo"
              className={styles.logo}
              src={ESA_logo}
              alt="ESA logo"
            />
            <h2 className={styles.subtitle}>
              To view your documents safely,
              <br />
              please sign in below.
            </h2>
          </div>

          {login.error && (
            <div className="alert alert-warning" role="alert">
              {JSON.stringify(login.error)}
            </div>
          )}

          {showEmailError && errors.email && (
            <div className="alert alert-warning" role="alert">
              {errors.email.message}
            </div>
          )}

          {showPasswordError && errors.password && (
            <div className="alert alert-warning" role="alert">
              {errors.password.message}
            </div>
          )}

          <div>
            <label className="form-label" htmlFor="email">
              Email
            </label>
            <input
              type="text"
              className={clx({
                "form-control": true,
                "is-invalid": !!errors.email && showEmailError,
              })}
              id="email"
              {...register("email", {
                onBlur: () => {
                  (async () => {
                    const valid = await trigger("email");
                    setShowEmailError(!valid);
                  })();
                },
                onChange: () => {
                  // Hide the error if the user starts typing
                  setShowEmailError(false);
                },
              })}
            />
          </div>

          <div className="form-group input-group">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                className={clx({
                  "form-control": true,
                  "is-invalid": !!errors.password && showPasswordError,
                })}
                id="password"
                {...register("password", {
                  onBlur: () => {
                    (async () => {
                      const valid = await trigger("password");
                      setShowPasswordError(!valid);
                    })();
                  },
                  onChange: () => {
                    setShowPasswordError(false);
                  },
                })}
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => setShowPassword(!showPassword)}>
                <i
                  className={`bi ${
                    showPassword ? "bi-eye" : "bi-eye-slash"
                  }`}></i>
              </button>
            </div>
          </div>

          <div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary btn-large">
              SIGN IN
            </button>
          </div>

          <div className="text-center">
            <a className="link-primary" href="./forgot">
              Forgot password?
            </a>
          </div>
        </section>

        <div className={styles.footer}>
          © {new Date().getFullYear()} ESA Verification
        </div>
      </div>
    </form>
  );
};
