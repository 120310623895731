import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { ApiProvider } from "./ApiProvider";

const getStorage = (rememberMe: boolean) =>
  rememberMe ? localStorage : sessionStorage;
const getSetting = <T>(key: string, defaultValue: T) => {
  const localStorageValue = localStorage.getItem(key);
  const sessionStorageValue = sessionStorage.getItem(key);
  // TODO: try to read it from cookie
  return localStorageValue || sessionStorageValue || defaultValue;
};
const saveSetting = (
  key: string,
  value: string | boolean | null,
  rememberMe = false
) => {
  const storage = getStorage(rememberMe);
  if (value === null) {
    storage.removeItem(key);
    return;
  }
  storage.setItem(key, `${value}`);
};
export enum Theme {
  Light = "light",
  Dark = "dark",
}
export interface IPreset {
  name: string;
}
interface IState {
  token: string;
  theme: Theme;
  isAdmin: boolean;
  isMobile: boolean;
  backgroundColor: string;
}

const initialState: IState = {
  token: getSetting("token", ""),
  isAdmin: false,
  isMobile: false,
  backgroundColor: "#FFFFFF",
  theme: (() => {
    switch (getSetting("portal_theme", Theme.Light)) {
      case Theme.Dark:
        return Theme.Dark;
      case Theme.Light:
      default:
        return Theme.Light;
    }
  })(),
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setToken(
      state,
      action: PayloadAction<{ token: string; rememberMe: boolean }>
    ) {
      const { token, rememberMe } = action.payload;
      try {
        const decoded = jwtDecode<{
          Company_Name: string;
          IsAdmin: string;
          exp: number;
        }>(token);

        const currentTime = Date.now() / 1000;
        const timeUntilExpiration = decoded.exp - currentTime;

        const logout = () => {
          saveSetting("token", null, false);
          saveSetting("token", null, true);
          location.href = "/login";
        };
        if (timeUntilExpiration > 0) {
          setTimeout(logout, timeUntilExpiration * 1000);
        } else {
          logout();
          return;
        }

        if (decoded && typeof decoded === "object") {
          state.isAdmin = decoded.IsAdmin == "True";
        }

        saveSetting("token", token, rememberMe);
        state.token = token;
        ApiProvider.default.setToken(token);
      } catch (error) {
        console.error("Failed to decode JWT token:", error);
      }
    },
    updateSettings: (state, action: PayloadAction<Partial<IState>>) => {
      return { ...state, ...action.payload };
    },
    setCompanyName(state, action: PayloadAction<string>) {
      saveSetting("savedCompanyName", action.payload, true);
    },
    clearToken(state) {
      saveSetting("token", null, false);
      saveSetting("token", null, true);
      return {
        ...state,
        token: "",
      };
    },
    changeTheme: (state, action: { payload: Theme }) => {
      saveSetting("portal_theme", action.payload, true);
      return {
        ...state,
        theme: action.payload,
      };
    },
  },
});
