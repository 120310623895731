import { clx } from "@src/components/utils/stringUtils";
import React from "react";
import styles from "./AppVip.module.scss";
import { LinkBtn } from "@src/components/linkBtn/LinkBtn";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import SupportPets_Dont_Leave_Them_Behind from "@src/img/SupportPets_Dont_Leave_Them_Behind.png";
import AppleWallet from "@src/img/AppleWallet.png";
import GoogleWallet from "@src/img/GoogleWallet.png";
import DogInCellphones from "@src/img/DogInCellphones.png";
import adBG from "@src/img/adBG.png";
import { AddToBtn } from "./AddToBtn/AddToBtn";

export const AppVip: React.FC = () => {
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <div className={styles.ad} style={{ backgroundImage: `url(${adBG})` }}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img
              alt="Support pets logo"
              src={SupportPets_Dont_Leave_Them_Behind}
            />
          </div>
          <div className={styles.header}>Download Your ESA FastPass</div>
          <div className={styles.subheader}>
            Display your ESA verification directly on
            <br /> from your smarthpohne.
          </div>
          <div className={styles.getItNow}>
            <LinkBtn
              href={`javascript:alert('Coming soon!')`}
              variant="secondary"
              text="Get It Now"
              bold={true}
              style={{ fontSize: "18px", width: "190px" }}
            />
          </div>
          <div className={styles.buttons}>
            <AddToBtn app="Apple Wallet" image={AppleWallet} />
            <AddToBtn app="Google wallet" image={GoogleWallet} />
          </div>
        </div>
        <div className={styles.cellphoneContainer}>
          <img alt="Dogs in cellphone" src={DogInCellphones} />
        </div>
      </div>
    </div>
  );
};
