import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  userActions,
  RootState,
  useAppDispatch,
  settingsActions,
} from "@src/store";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import { toast } from "sonner";
import { passwordComplexityRegex } from "@src/store/helpers";
import styles from "./Reset.module.scss";
import ESA_logo from "@src/img/ESA_logo.png";
import { useSelector } from "react-redux";
import { clx } from "@src/components/utils/stringUtils";
import { useAuth } from "@src/routes/authProvider";

export const ResetValidation = yup.object().shape({
  isAuthenticated: yup.boolean(),
  currentPassword: yup.string().when("isAuthenticated", {
    is: true,
    then: (schema) =>
      schema
        .required("Current password is required.")
        .matches(
          passwordComplexityRegex,
          "Password must be 8-32 characters long, include at least one number, and one special character (!@#$%^&*)."
        ),
    otherwise: (schema) => schema.notRequired().nullable(),
  }),
  newPassword: yup
    .string()
    .required("Password field cant be empty.")
    .matches(
      passwordComplexityRegex,
      "Password must be 8-32 characters long, include at least one number, and one special character (!@#$%^&*)."
    ),
  newPasswordVerification: yup
    .string()
    .required("Password field cant be empty.")
    .oneOf([yup.ref("newPassword")], "Passwords must match."),
});
export type ResetValidationProps = yup.InferType<typeof ResetValidation>;

export const Reset: React.FC = () => {
  const dispatch = useAppDispatch();

  dispatch(
    settingsActions.updateSettings({
      backgroundColor: "#0b7cbb",
    })
  );

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordVerification, setShowNewPasswordVerification] =
    useState(false);

  const navigate = useNavigate();
  const passwordReset = useSelector((x: RootState) => x.passwordReset);
  const [resetUuid, setResetUuid] = useState("");

  const { isAuthenticated } = useAuth();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, touchedFields },
  } = useForm<ResetValidationProps>({
    mode: "onChange",
    resolver: yupResolver(ResetValidation),
    defaultValues: {
      isAuthenticated,
      currentPassword: "",
      newPassword: "",
      newPasswordVerification: "",
    },
  });
  const onSubmit: SubmitHandler<ResetValidationProps> = async () => {
    try {
      const values = getValues();

      const result = isAuthenticated
        ? await dispatch(
            userActions.passwordReset({
              currentPassword: values.currentPassword ?? "",
              newPassword: values.newPassword,
              newPasswordVerification: values.newPasswordVerification,
            })
          )
        : await dispatch(
            userActions.passwordResetUUID({
              resetUuid: resetUuid,
              newPassword: values.newPassword,
              newPasswordVerification: values.newPasswordVerification,
            })
          );
      const response = unwrapResult(result);
      if (response.success) {
        navigate(isAuthenticated ? paths.esaDocuments : paths.login);
      }
    } catch (err: unknown) {
      console.error(err);
      if (typeof err === "string") {
        toast.warning(err, {
          duration: Infinity,
          className: "custom-toast-button",
          action: {
            label: "Dismiss",
            onClick: () => toast.dismiss(),
          },
        });
      } else {
        toast.warning("Reset failed");
      }
    }
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search.toLowerCase());
    const uuid = isAuthenticated
      ? "00000000-0000-0000-0000-000000000000"
      : params.get("uuid");
    if (!uuid) {
      navigate("/login");
    } else {
      setResetUuid(uuid);
    }
  }, [navigate]);

  return (
    <form className={styles.main} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.center}>
        <section className={clx("d-flex flex-column gap-3", styles.form)}>
          <div className={styles.header}>
            <h1 className={styles.title}>ESA Verification</h1>
            <img
              id="esa-logo"
              className={styles.logo}
              src={ESA_logo}
              alt="ESA logo"
            />
            <h2 className={styles.subtitle}>
              Create a new password to access your documents.
            </h2>
          </div>
          {passwordReset.error && (
            <div className="alert alert-warning" role="alert">
              {JSON.stringify(passwordReset.error)}
            </div>
          )}
          {errors.newPassword && touchedFields.newPassword && (
            <div className="alert alert-warning" role="alert">
              {errors.newPassword.message}
            </div>
          )}
          {errors.newPasswordVerification &&
            touchedFields.newPasswordVerification && (
              <div className="alert alert-warning" role="alert">
                {errors.newPasswordVerification.message}
              </div>
            )}

          <div className={clx("alert alert-light", styles.rules)} role="alert">
            <div>PASSWORD MUST BE</div>
            <ul>
              <li>At least 8 and up to 32 characters long</li>
              <li>Should consist of a-z, A-Z, 0-9</li>
              <li>Include 1 or more numbers</li>
              <li>Include 1 or more characters (!@#$%^&*)</li>
            </ul>
          </div>

          {isAuthenticated && (
            <div className="form-group input-group">
              <label className="form-label" htmlFor="currentPassword">
                Current password:
              </label>
              <div className="input-group">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  className={clx({
                    "form-control": true,
                    "is-invalid": !!errors.currentPassword,
                  })}
                  id="currentPassword"
                  {...register("currentPassword")}
                />
                <button
                  type="button"
                  className="input-group-text"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                  <i
                    className={`bi ${showCurrentPassword ? "bi-eye" : "bi-eye-slash"}`}></i>
                </button>
              </div>
            </div>
          )}
          <div className="form-group input-group">
            <label className="form-label" htmlFor="newPassword">
              New password:
            </label>
            <div className="input-group">
              <input
                type={showNewPassword ? "text" : "password"}
                className={clx({
                  "form-control": true,
                  "is-invalid": !!errors.newPassword,
                })}
                id="newPassword"
                {...register("newPassword")}
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() => setShowNewPassword(!showNewPassword)}>
                <i
                  className={`bi ${showNewPassword ? "bi-eye" : "bi-eye-slash"}`}></i>
              </button>
            </div>
          </div>
          <div className="form-group input-group">
            <label className="form-label" htmlFor="newPasswordVerification">
              Re-enter Password:
            </label>
            <div className="input-group">
              <input
                type={showNewPasswordVerification ? "text" : "password"}
                className={clx({
                  "form-control": true,
                  "is-invalid": !!errors.newPassword,
                })}
                id="newPasswordVerification"
                {...register("newPasswordVerification")}
              />
              <button
                type="button"
                className="input-group-text"
                onClick={() =>
                  setShowNewPasswordVerification(!showNewPasswordVerification)
                }>
                <i
                  className={`bi ${showNewPasswordVerification ? "bi-eye" : "bi-eye-slash"}`}></i>
              </button>
            </div>
          </div>
          <div>
            <button
              disabled={isSubmitting || Object.keys(errors).length > 0}
              type="submit"
              className="btn btn-primary btn-large">
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>{" "}
                  Setting new password
                </>
              ) : (
                "Set new password"
              )}
            </button>
          </div>
          <div className="text-center">
            {isAuthenticated ? (
              <a className="link-primary" href="./">
                Home
              </a>
            ) : (
              <a className="link-primary" href="./login">
                Login
              </a>
            )}
          </div>
        </section>

        <div className={styles.footer}>
          © {new Date().getFullYear()} ESA Verification
        </div>
      </div>
    </form>
  );
};
