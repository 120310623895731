import React from "react";
import styles from "./Vip.module.scss";
import vip from "@src/img/vip.png";
import { clx } from "@src/components/utils/stringUtils";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import { Box } from "./Box/Box";
import VisitWithExpert from "@src/img/VisitWithExpert.png";
import LearnAtYourOwn from "@src/img/LearnAtYourOwn.png";
import MonitorYourPet from "@src/img/MonitorYourPet.png";
import { LinkBtn } from "@src/components/linkBtn/LinkBtn";
import { ShareVip } from "./ShareVip/ShareVip";
import { AppVip } from "./AppVip/AppVip";

export const Vip: React.FC = () => {
  const user = useSelector((x: RootState) => x.user);
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <div className={styles.logo}>
        <img alt="vip logo" src={vip} />
      </div>
      <div className={styles.header}>Welcome to the V.I.P Club</div>
      <div className={styles.welcome}>
        We’re thrilled to have you as part of the V.I.P. Club! This page is your
        one-stop hub for all the exclusive benefits and resources that come with
        your membership. From 24/7 tele-vet support to on-demand training and
        pet tracking, everything you need to make life easier with your ESA is
        right here.
      </div>
      <div className={styles.boxContainer}>
        <Box
          bgImage={VisitWithExpert}
          linkText="Connect Now"
          linkHref="https://supportpets.teletails.com/"
          info="Visit with a vet expert 24/7 via chat or video without leaving home."
        />
        <Box
          bgImage={LearnAtYourOwn}
          linkText="Get Access"
          linkHref="https://www.icti.org/login"
          info="Learn at your own pace with training courses tailored to your pet’s
            needs."
        />
        <Box
          bgImage={MonitorYourPet}
          linkText="Login Now"
          linkHref="https://geniustag.com/login"
          info="Monitor your pet’s location with GeniusTag and ensure your ESA stays
            safe."
        />
      </div>
      <div className={styles.manageSubscription}>
        <div className={styles.header}>Manage Your Subscription</div>
        <LinkBtn
          href={`https://form.jotform.com/242667358881168?email=${user.data.email}&opId=${user.data.userId}`}
          variant="primary"
          text="Cancel Membership"
          bold={true}
          style={{ fontSize: "18px", width: "255px" }}
        />
      </div>
      <ShareVip />
      <AppVip />
    </div>
  );
};
