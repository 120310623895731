import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  Draft,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ApiProvider } from "./ApiProvider";
import { getInitialState, IDataState } from "./helpers";

type WithSkipCache<T> = T & { skipCache?: boolean };
type IExtraReducers<TState> = (
  builder: ActionReducerMapBuilder<IDataState<TState>>
) => void;

export const handleError = (error: unknown) => {
  if (error instanceof AxiosError) {
    let responseError = error.response?.data ?? error.response?.data?.Message;
    const statusCode = error.response?.status;
    if (statusCode === 429) {
      responseError = "Too many requests, please try again in a minute";
    }

    return responseError.error ?? responseError ?? error.message;
  } else if (error instanceof Error) {
    return error.message;
  }

  return "Unknown error";
};

export const getThunkAndSlice = <TParams, TResponse, TState = TResponse>({
  path,
  initialState,
  cacheId,
  responsePath,
  method = "get",
  onBeforeRequest,
  onAfterRequest,
  onData,
}: {
  path: string | ((params: TParams) => string);
  initialState: TState;
  cacheId?: keyof TParams;
  method?: "post" | "get" | "postWithFile";
  responsePath?: keyof TResponse;
  onBeforeRequest?: (params: TParams, state: unknown) => TState | undefined;
  onAfterRequest?: (
    data: TState,
    params: TParams,
    state: unknown,
    dispatch: unknown
  ) => TState | undefined;
  onData?: (data: TState) => void;
}) => {
  const iState = getInitialState(initialState);

  const getCacheKey = (params: TParams, cacheId?: keyof TParams): string => {
    if (!params || !cacheId) return "";
    return String(params[cacheId]);
  };

  const pathKey = (
    typeof path === "function" ? path({} as TParams) : path
  ).replace(/\//g, "_");

  const thunk = createAsyncThunk<TState, WithSkipCache<TParams>>(
    pathKey,
    async (params, opts) => {
      const { rejectWithValue, getState, dispatch } = opts;
      try {
        const state = getState();

        const result = onBeforeRequest?.(params, state);
        if (result) return result;

        const pathString = typeof path === "function" ? path(params) : path;
        const cacheKey = getCacheKey(params, cacheId);

        if (!params.skipCache) {
          const cached = ApiProvider.default.getCache<TState>(
            pathKey,
            cacheKey
          );
          if (cached) return cached;
        }

        const response = await ApiProvider.default[method]<TResponse>(
          pathString,
          params || {}
        );
        let data = (responsePath ? response[responsePath] : response) as TState;

        const newData = onAfterRequest?.(data, params, state, dispatch);
        if (newData) data = newData;

        ApiProvider.default.setCache(pathKey, cacheKey, data);
        return data;
      } catch (error: unknown) {
        return rejectWithValue(handleError(error));
      }
    }
  );
  const extraReducers: IExtraReducers<TState> = (builder) => {
    builder
      .addCase(thunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(thunk.fulfilled, (state, action) => {
        state.loading = false;
        state.version++;
        state.init = true;
        state.data = action.payload as Draft<TState>;
        onData?.(action.payload);
        state.error = null;
      })
      .addCase(thunk.rejected, (state, action) => {
        state.loading = false;
        state.data = iState.data as Draft<TState>;
        state.error = `${action.payload}`;
      });
  };

  const slice = createSlice({
    name: pathKey,
    initialState: iState,
    reducers: {
      reset: (state) => {
        state.data = iState.data as Draft<TState>;
      },
      update: (state, data) => {
        state.data = data.payload as Draft<TState>;
      },
    },
    extraReducers,
  });

  return {
    thunk,
    slice,
    extraReducers,
    pathKey,
    getCacheKey,
  };
};
