import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loginSlice } from "./account/loginSlice";
import { settingsSlice } from "./settingsSlice";
import { forgotSlice } from "./account/forgotSlice";
import { passwordResetSlice } from "./account/passwordResetSlice";
import { passwordResetUUIDSlice } from "./account/passwordResetUUIDSlice";
import { userSlice } from "./account/user";

// import { getListingRulesSlice } from "./auto-pricer/getListingRulesSlice";

// const autoPricer = combineReducers({
//   listingRules: getListingRulesSlice.reducer,
// });

const rootReducer = combineReducers({
  login: loginSlice.reducer,
  forgot: forgotSlice.reducer,
  user: userSlice.reducer,
  passwordReset: passwordResetSlice.reducer,
  passwordResetUUID: passwordResetUUIDSlice.reducer,
  settings: settingsSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
