import { useDispatch } from "react-redux";
import { login } from "./account/loginSlice";
import { validateToken } from "./account/validateTokenSlice";
import { settingsSlice } from "./settingsSlice";
import { store } from "./store";
import { forgot } from "./account/forgotSlice";
import { passwordReset } from "./account/passwordResetSlice";
import { passwordResetUUID } from "./account/passwordResetUUIDSlice";
import { userGetInfoThunk, videoViewedThunk } from "./account/user";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const userActions = {
  login,
  forgot,
  user: userGetInfoThunk,
  videoViewed: videoViewedThunk,
  passwordReset,
  passwordResetUUID,
  validateToken,
};

export const settingsActions = {
  ...settingsSlice.actions,
};
