import React from "react";
import styles from "./EsaDocuments.module.scss";
import { clx } from "@src/components/utils/stringUtils";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import { MinimalPlayer } from "./MinimalPlayer";
import ESA_Leash from "@src/img/ESA_Leash.png";
import ESA_Vest from "@src/img/ESA_Vest.png";
import Genius_Tag from "@src/img/Genius_Tag.png";
import { LinkBtn } from "@src/components/linkBtn/LinkBtn";

export const EsaDocuments: React.FC = () => {
  const user = useSelector((x: RootState) => x.user);
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <h3 className={clx(styles.title, styles.center)}>
        Unlock Your Official ESA®{user.data.psd_docs ? " & PSD Approvals" : ""}
      </h3>

      <div className={clx(styles.subtitle, styles.center)}>
        Watch this short video to learn how to maximize your rights and access
        your ESA®{user.data.psd_docs ? " & PSD documents" : ""}
      </div>

      <div className={styles.videoContainer}>
        <MinimalPlayer
          vimeoId="975478532?h=f556d3765d"
          originalWidth={1920}
          originalHeight={1080}
          width={403}
          showPercent={0.75}
          initialWasViewed={user.data.video_viewed}
        />
      </div>
      <div className={clx(styles.boldBtns, styles.center)}>
        {user.data.psd_docs == "" ? (
          <>
            Your ESA Documents Have Expired. Please{" "}
            <a
              className={styles.blackLink}
              href="https://drive.google.com/open">
              renew them here.
            </a>
          </>
        ) : (
          <>
            Your ESA Verification number is: <b>ESA369{user.data.userId}</b>
          </>
        )}
      </div>

      <div>
        <b>Dear {`${user.data.firstname} ${user.data.lastname}`},</b>
      </div>
      <div>
        Congrats on your <b>Official ESA® approval </b>
        {user.data.psd_docs && (
          <>
            and <b>Official PSD® recommendation</b>{" "}
          </>
        )}
        from a licensed professional in your area!
      </div>
      <div>
        You’ve taken the first step toward more freedom and peace of mind. Now,
        let’s make sure you and your ESA
        {user.data.psd_docs && <>—and soon-to-be-trained PSD—</>}
        can go anywhere, anytime, without stress or hassle.
      </div>
      <div>
        <b>
          Your documents will be available for download at the bottom of this
          page once you’ve reviewed the valuable resources below.
        </b>
      </div>
      <div className={styles.divisor}></div>
      <div>
        <b>Make Your Official ESA® Look the Part</b>
      </div>

      <div>
        Want to avoid any confusion in public spaces? Properly labeled ESA gear
        makes it 10x less likely you’ll face hassles or questions. Outfit your
        ESA with a vest, leash, ID tags, and more to ensure easy recognition
        everywhere.
      </div>
      <div
        className={clx(
          styles.images,
          "images space buttons-container enabled"
        )}>
        <div className={styles.iconWButton}>
          <a
            href="https://secure.supportpets.com/esa-verification-customer-leash"
            target="blank">
            <img alt="ESA leash" src={ESA_Leash} />
          </a>
          <LinkBtn
            variant="primary"
            bold={true}
            href="https://secure.supportpets.com/esa-verification-customer-leash"
            text="Get Your ESA Leash"
          />
        </div>
        <div className={styles.iconWButton}>
          <a
            href="https://qualified.supportpets.com/products/official-esa-vest-evp"
            target="blank">
            <img alt="ESA Vest" src={ESA_Vest} />
          </a>
          <LinkBtn
            variant="primary"
            bold={true}
            href="https://qualified.supportpets.com/products/official-esa-vest-evp"
            text="Secure The ESA Vest"
          />
        </div>
        <div className={styles.iconWButton}>
          <a
            href="https://geniustag.com/pet-id-sp?affiliate=616383c1-7610-439c-b386-0866d1b75aef"
            target="blank">
            <img alt="Genius Tag" src={Genius_Tag} />
          </a>
          <LinkBtn
            variant="primary"
            bold={true}
            href="https://geniustag.com/pet-id-sp?affiliate=616383c1-7610-439c-b386-0866d1b75aef"
            text="Order Your GeniusTag"
          />
        </div>
      </div>

      {user.data.psd_docs && (
        <>
          <div className={styles.divisor}></div>
          <div>
            <b>Upgrade Your Official ESA® to a Service Status</b>
          </div>

          <div>
            Your PSD recommendation letter is included in the package you'll
            download below. Now, all that's left to complete your dog's Service
            Dog status is the proper training.
          </div>
          <div>
            Instead of paying $15,000 to $20,000 for a trainer, you can
            fast-track the process with our easy home study course through
            “International Canine Training Institute”—all for a fraction of the
            cost. Get your dog ready for Service Dog status quickly and
            affordably.
          </div>
          <div className={styles.center}>
            <LinkBtn
              variant="secondary"
              bold={true}
              href="https://secure.supportpets.com/esa-verification-customer-psd"
              text="Start Your Service Dog Training Today"
            />
          </div>
        </>
      )}

      <div className={styles.divisor}></div>
      <div>
        <b>Share the Love and Earn $20 Every Time</b>
      </div>
      <div>
        Spread the peace of mind your ESA or PSD brings by referring friends and
        family. For each person who signs up and gets approved, you’ll receive a
        $20 Amazon Gift Card—even a spouse, partner, or adult child can benefit.
        It’s a win-win!
      </div>
      <div className={styles.center}>
        <LinkBtn
          variant="secondary"
          bold={true}
          href="https://www.supportpets.com/refer-a-friend-esa-portal-simple-email/"
          text="Click to refer a couple now"
        />
      </div>
      <div className={styles.divisor}></div>
      <div>
        <b>Moving Soon?</b>
      </div>
      <div>
        If your landlord requests an ESA verification form, we’ve got you
        covered. You can easily request a signed form from a licensed health
        professional. Know your rights under the Fair Housing Act (FHA) and
        ensure you and your ESA are protected.
      </div>

      <div className={styles.center}>
        <LinkBtn
          variant="secondary"
          bold={true}
          href="https://secure.supportpets.com/landlord-form-upload"
          text="Submit ESA Verification Form"
        />
      </div>
      <div className={styles.center}></div>
      <div>
        <b>
          Ready to Access Your ESA® {user.data.psd_docs && " & PSD "}Documents?
        </b>
      </div>
      {user.data.video_viewed ? (
        <div>
          To access your Official ESA®{" "}
          {user.data.psd_docs && "and PSD documents "}, please watch the entire
          video above. Then, click below to access your documents.
        </div>
      ) : (
        <div>
          You’ve completed the first steps! Now, it's time to access your
          Official ESA®{user.data.psd_docs && " and PSD documents"}. Click the
          button below to download them and ensure you're ready to make the most
          of your pet’s new official status.
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <div className={styles.buttons}>
          {user.data.esa_docs && (
            <LinkBtn
              variant="primary"
              bold={true}
              disabled={!user.data.video_viewed}
              href={`${user.data.esa_docs}`}
              text={
                <>
                  Access Your ESA
                  <br /> Documents
                </>
              }
            />
          )}
          {user.data.psd_docs && (
            <LinkBtn
              variant="primary"
              bold={true}
              disabled={!user.data.video_viewed}
              href={`${user.data.psd_docs}`}
              text={
                <>
                  Access Your PSD
                  <br /> Recomendation Letter
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
