import React, { useEffect } from "react";

import styles from "./App.module.scss";
import { Routes } from "./routes/Routes";
import { Toaster } from "sonner";
import { RootState, settingsActions, useAppDispatch } from "@src/store";
import { debounce } from "lodash";
import { useSelector } from "react-redux";

function App() {
  const dispatch = useAppDispatch();

  const backgroundColor = useSelector(
    (x: RootState) => x.settings.backgroundColor
  );

  const handleResize = () => {
    const isMobile =
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      (window.innerWidth <= 768 && window.innerHeight <= 1024);
    const isMobileFromUrl =
      new URLSearchParams(window.location.search).get("isMobile") === "true";

    dispatch(
      settingsActions.updateSettings({
        isMobile: isMobile || isMobileFromUrl,
      })
    );
  };

  useEffect(() => {
    const debounceResize = debounce(handleResize, 150);
    window.addEventListener("resize", debounceResize);
    return () => window.removeEventListener("resize", debounceResize);
  }, []);

  return (
    <div className={styles.App} style={{ backgroundColor: backgroundColor }}>
      <Routes />
      <Toaster
        richColors
        className={styles.toaster}
        duration={2000}
        position="top-center"
        expand={false}
      />
    </div>
  );
}

export default App;
