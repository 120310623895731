import { clx } from "@src/components/utils/stringUtils";
import React from "react";
import styles from "./ShareVip.module.scss";
import { LinkBtn } from "@src/components/linkBtn/LinkBtn";
import DogLap from "@src/img/DogLap.png";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export const ShareVip: React.FC = () => {
  const isMobile = useSelector((x: RootState) => x.settings.isMobile);

  return (
    <div className={clx({ [styles.main]: true, [styles.isMobile]: isMobile })}>
      <div className={styles.imgContainer}>
        <img alt="Dog laptop" className={styles.img} src={DogLap} />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>Share the VIP Experience</div>
        <div className={styles.subheader}>
          Love being a part of our V.I.P. Club? Spread the
          <br /> word! When your friends or family join through your referral,
          <br /> you'll earn exclusive rewards.
        </div>
        <div className={styles.buttons}>
          <LinkBtn
            href="https://www.supportpets.com/refer-a-friend-esa-portal-simple-email/"
            variant="primary"
            text="Share"
            bold={true}
            style={{ fontSize: "18px", width: "150px" }}
          />
        </div>
      </div>
    </div>
  );
};
