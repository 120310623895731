import { ApiProvider } from "@src/store/ApiProvider";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
import { jwtDecode } from "jwt-decode";

const initialState = {
  result: "",
  message: "",
  userid: "",
  logged: false,
  token: "",
};
export const { thunk: login, slice: loginSlice } = getThunkAndSlice<
  {
    email: string;
    password: string;
  },
  typeof initialState
>({
  initialState,
  path: "/user/login",
  method: "post",
  onBeforeRequest: () => {
    const token = localStorage.getItem("token");
    if (token) {
      ApiProvider.default.setToken(token);
      const decoded = jwtDecode<{ userid: string }>(token);
      return {
        result: "ok",
        message: "Success",
        userid: decoded.userid,
        logged: true,
        token,
      };
    }
  },
  onData: (data) => {
    ApiProvider.default.setToken(data.token);
  },
});
