import React, { useEffect, useRef } from "react";
import { clx } from "../utils/stringUtils";
import styles from "./LinkBtn.module.scss";

export const LinkBtn: React.FC<{
  href: string;
  variant: "primary" | "secondary";
  text: string | JSX.Element;
  bold?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}> = ({ href, variant, text, bold = false, disabled = false, style = {} }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (linkRef.current) {
      linkRef.current.setAttribute("href", href);
    }
  }, [href]);

  return (
    <a
      ref={linkRef}
      href={href.indexOf("javascript:") > -1 ? "#" : href}
      className={clx({
        [styles.main]: true,
        [styles.secondary]: variant == "secondary",
        [styles.primary]: variant == "primary",
        [styles.bold]: bold,
        [styles.disabled]: disabled,
      })}
      style={style}
      target="_blank"
    >
      {text}
    </a>
  );
};
