import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { paths } from "./paths";

import { Loader } from "@src/components/loader/Loader";
import { Home } from "@src/pages/home/Home";
import { Login } from "@src/pages/login/Login";
import { Forgot } from "@src/pages/forgot/Forgot";
import { Dashboard } from "@src/pages/dashboard/Dashboard";
import { Reset } from "@src/pages/reset/Reset";

export const Routes = () => {
  return useRoutes([
    {
      path: paths.login,
      element: (
        <Suspense fallback={<Loader controlSize="xxl" />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: paths.forgot,
      element: (
        <Suspense fallback={<Loader controlSize="xxl" />}>
          <Forgot />
        </Suspense>
      ),
    },
    {
      path: paths.reset,
      element: (
        <Suspense fallback={<Loader controlSize="xxl" />}>
          <Reset />
        </Suspense>
      ),
    },
    {
      path: paths.home,
      element: <Home />,
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<Loader controlSize="xxl" />}>
              <Dashboard />
            </Suspense>
          ),
        },
      ],
    },
  ]);
};
