export interface IDataState<T> {
  loading: boolean;
  init: boolean;
  version: number;
  data: T;
  error: string | null;
}
export const getInitialState = <T>(data: T): IDataState<T> => {
  return {
    loading: false,
    init: false,
    version: 0,
    error: null,
    data: data,
  };
};
export const fromType = <T>(obj = {}): T => obj as T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ANY = any;

export const passwordComplexityRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/;

export const getRootUrl = () => {
  console.log(
    "process.env",
    process.env,
    process.env.PRODUCTION,
    process.env.API_PORT
  );
  const url = new URL(window.location.href);
  const rootUrl = `${url.protocol}//${url.hostname}${process.env.PRODUCTION == "true" ? "" : ":" + process.env.API_PORT}`;
  return rootUrl;
};

export const getBackEndUrl = (path: string) => {
  return `${getRootUrl()}/api/${path}`;
};
