import { ApiProvider } from "@src/store/ApiProvider";
import { getThunkAndSlice } from "@src/store/getThunkAndSlice";
const initialState = {
	isValid: false,
};
export const { thunk: validateToken, slice: validateTokenSlice } = getThunkAndSlice<
	{
		token: string;
	},
	typeof initialState
>({
	initialState,
	path: "/Account/Validate_Token",
	method: "post",
	onBeforeRequest: (_) => {
		const saved_token = localStorage.getItem("token");
		if (saved_token) {
			ApiProvider.default.setToken(saved_token);
			return { isValid: true };
		}
	},
});
