import { RootState, settingsActions, useAppDispatch } from "@src/store";
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext<{
  isAuthenticated: boolean;
  login: (token: string, rememberMe: boolean, hideSideBar: boolean) => void;
  logout: () => void;
  rememberMe: boolean;
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isAuthenticated: false,
  login: (_token: string, _rememberMe: boolean, _hideSideBar: boolean) => {
    // intentionally left blank
  },
  logout: () => {
    // intentionally left blank
  },
  rememberMe: false,
  setRememberMe: () => {
    // intentionally left blank
  },
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const token = useSelector((state: RootState) => state.settings.token);

  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  const login = (token: string, rememberMe: boolean) => {
    appDispatch(settingsActions.setToken({ token, rememberMe }));
  };
  const logout = () => {
    appDispatch(settingsActions.clearToken());
    navigate("/login");
  };
  // TODO: validate token
  const isAuthenticated = !!token;

  useEffect(() => {
    if (!isAuthenticated) return;
    appDispatch(settingsActions.setToken({ token, rememberMe }));
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, rememberMe, setRememberMe }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
