// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Box-module_main___n6r8m{display:flex;flex-direction:column;width:245px}.Box-module_main___n6r8m .Box-module_content___Cov0d{background-size:cover;background-repeat:no-repeat;width:245px;height:245px;display:flex;align-items:end;justify-content:center;padding-bottom:10px}.Box-module_main___n6r8m .Box-module_text___RwGQo{font-size:12px;font-weight:300;padding-top:10px;font-style:italic}.Box-module_main___n6r8m.Box-module_isMobile___BXXr9{width:3100%}.Box-module_main___n6r8m.Box-module_isMobile___BXXr9 .Box-module_content___Cov0d{width:100%;height:355px !important}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/vip/Box/Box.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,qDACE,qBAAA,CACA,2BAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,eAAA,CACA,sBAAA,CACA,mBAAA,CAEF,kDACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAEF,qDACE,WAAA,CACA,iFACE,UAAA,CACA,uBAAA","sourcesContent":[".main{display:flex;flex-direction:column;width:245px}.main .content{background-size:cover;background-repeat:no-repeat;width:245px;height:245px;display:flex;align-items:end;justify-content:center;padding-bottom:10px}.main .text{font-size:12px;font-weight:300;padding-top:10px;font-style:italic}.main.isMobile{width:3100%}.main.isMobile .content{width:100%;height:355px !important}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Box-module_main___n6r8m`,
	"content": `Box-module_content___Cov0d`,
	"text": `Box-module_text___RwGQo`,
	"isMobile": `Box-module_isMobile___BXXr9`
};
export default ___CSS_LOADER_EXPORT___;
