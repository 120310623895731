import { getThunkAndSlice } from "@src/store/getThunkAndSlice";

const initialState = {
  success: false,
};
export const { thunk: passwordResetUUID, slice: passwordResetUUIDSlice } =
  getThunkAndSlice<
    {
      newPassword: string;
      newPasswordVerification: string;
      resetUuid: string;
    },
    typeof initialState
  >({
    initialState,
    path: "/user/password-reset-uuid",
    method: "post",
  });
