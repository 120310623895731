import React from "react";
import styles from "./AddToBtn.module.scss";

export const AddToBtn: React.FC<{
  app: string;
  image: string;
}> = ({ app, image }) => {
  return (
    <button
      className={styles.main}
      onClick={() => {
        alert("Coming soon!");
        return false;
      }}
    >
      <div className={styles.img}>
        <img alt={app} src={image} />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>Add to</div>
        <div className={styles.subheader}>{app}</div>
      </div>
    </button>
  );
};
