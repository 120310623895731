// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader-module_main___kNA7f{min-height:100vh;height:100%;width:100%;display:grid;place-content:center}.Loader-module_main___kNA7f[data-is-full-page=true]{min-height:100vh}.Loader-module_main___kNA7f[data-is-full-page=false]{min-height:auto}.Loader-module_main___kNA7f .Loader-module_bouncerLoader___JS7dg{color:#000}`, "",{"version":3,"sources":["webpack://./src/components/loader/Loader.module.scss"],"names":[],"mappings":"AAAA,4BACE,gBAAA,CACA,WAAA,CACA,UAAA,CACA,YAAA,CACA,oBAAA,CAEA,oDACE,gBAAA,CAGF,qDACE,eAAA,CAEF,iEACE,UAAA","sourcesContent":[".main{min-height:100vh;height:100%;width:100%;display:grid;place-content:center}.main[data-is-full-page=true]{min-height:100vh}.main[data-is-full-page=false]{min-height:auto}.main .bouncerLoader{color:#000}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Loader-module_main___kNA7f`,
	"bouncerLoader": `Loader-module_bouncerLoader___JS7dg`
};
export default ___CSS_LOADER_EXPORT___;
