import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { handleError } from "@src/store/getThunkAndSlice";
import { ApiProvider } from "../ApiProvider";
import { getInitialState } from "../helpers";

const userInitialState = {
  hasVIP: false,
  hasChat: false,
  hasTelevet: false,
  email: "",
  is_over_18: false,
  gender: "",
  parent_id: 0,
  userId: 0,
  psd_docs: "",
  esa_docs: "",
  firstname: "",
  lastname: "",
  video_viewed: false,
};
export const userGetInfoThunk = createAsyncThunk<typeof userInitialState>(
  "/user/get-info",
  async (_params, opts) => {
    const { rejectWithValue } = opts;
    try {
      const response =
        (await ApiProvider.default.post<typeof userInitialState>(
          "/user/get-info",
          {}
        )) ?? userInitialState;

      return response;
    } catch (error: unknown) {
      return rejectWithValue(handleError(error));
    }
  }
);

const videoViewedInitialState = {
  video_viewed: false,
};
export const videoViewedThunk = createAsyncThunk<
  {
    video_viewed: boolean;
  },
  {
    video_viewed: boolean;
  }
>("/user/video-viewed", async (params, opts) => {
  const { rejectWithValue } = opts;
  try {
    const response =
      (await ApiProvider.default.post<typeof videoViewedInitialState>(
        "/user/video-viewed",
        params
      )) ?? videoViewedInitialState;

    return response;
  } catch (error: unknown) {
    return rejectWithValue(handleError(error));
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState: getInitialState(userInitialState),
  reducers: {
    reset: (state) => {
      state.data = userInitialState;
    },
    update: (state, data) => {
      state.data = {
        ...state.data,
        ...data.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userGetInfoThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(userGetInfoThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.version++;
        state.init = true;
        state.data = action.payload ?? userInitialState;
        state.error = null;
      })
      .addCase(userGetInfoThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
      })

      .addCase(videoViewedThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(videoViewedThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.version++;
        state.init = true;
        state.data = {
          ...state.data,
          video_viewed: action.payload.video_viewed,
        };
        state.error = null;
      });
  },
});
