// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard-module_loading___B0eo9{background-color:#fff;color:#343434;max-width:856px;margin:0 auto;padding:10px 0px;display:flex;gap:10px;align-items:center}.Dashboard-module_tabs___eJ4lW{background-color:#fff;max-width:856px;margin:0 auto;padding:10px 0px;display:flex;gap:10px}.Dashboard-module_tabs___eJ4lW .Dashboard-module_tab___jRMRf{background-color:rgba(0,0,0,0);border:none;font-size:15px;font-weight:600;padding:5px 10px;cursor:pointer;color:#343434;user-select:none}.Dashboard-module_tabs___eJ4lW .Dashboard-module_tab___jRMRf.Dashboard-module_disabled___V2Jir{color:#b3b3b3;pointer-events:none;display:none}.Dashboard-module_tabs___eJ4lW .Dashboard-module_tab___jRMRf.Dashboard-module_selected___dN0ZD{color:#0a7cbb;border-bottom:3px solid #0a7cbb}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Dashboard.module.scss"],"names":[],"mappings":"AAAA,kCACE,qBAAA,CACA,aAAA,CACA,eAAA,CACA,aAAA,CACA,gBAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CAEF,+BACE,qBAAA,CACA,eAAA,CACA,aAAA,CACA,gBAAA,CACA,YAAA,CACA,QAAA,CACA,6DACE,8BAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,cAAA,CACA,aAAA,CACA,gBAAA,CACA,+FACE,aAAA,CACA,mBAAA,CACA,YAAA,CAEF,+FACE,aAAA,CACA,+BAAA","sourcesContent":[".loading{background-color:#fff;color:#343434;max-width:856px;margin:0 auto;padding:10px 0px;display:flex;gap:10px;align-items:center}.tabs{background-color:#fff;max-width:856px;margin:0 auto;padding:10px 0px;display:flex;gap:10px}.tabs .tab{background-color:rgba(0,0,0,0);border:none;font-size:15px;font-weight:600;padding:5px 10px;cursor:pointer;color:#343434;user-select:none}.tabs .tab.disabled{color:#b3b3b3;pointer-events:none;display:none}.tabs .tab.selected{color:#0a7cbb;border-bottom:3px solid #0a7cbb}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `Dashboard-module_loading___B0eo9`,
	"tabs": `Dashboard-module_tabs___eJ4lW`,
	"tab": `Dashboard-module_tab___jRMRf`,
	"disabled": `Dashboard-module_disabled___V2Jir`,
	"selected": `Dashboard-module_selected___dN0ZD`
};
export default ___CSS_LOADER_EXPORT___;
